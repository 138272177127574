<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ContestManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.contest') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
											<div class="table__filter--order">
												<multiselect
													class="select__status"
													v-model="filterCategories"
													:options="orderList"
													label="name"
													:clearOnSelect="false"
													:clear-on-select="false"
													:placeholder="$t('placeholder.choose')"
												>
													<template slot="singleLabel" slot-scope="{ option }">{{
														option.name
													}}</template>
													<template slot="noResult">{{ $t('components.not_found') }}</template>
												</multiselect>
											</div>
										</div>
										<div class="table__filter--right">
											<router-link
												:to="'/' + $i18n.locale + '/account/contest/create'"
												v-if="$store.getters.userPermission(['change_contest'])"
												class="btn--link btn--link--plus"
											>
												{{ $t('labels.add_new_result_record') }}
												<img src="../../../assets/img/icon-plus.svg" />
											</router-link>
										</div>
									</div>

									<v-data-table
										class="table__block--wrap"
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.document="{ item }">
											<a
												:href="urlApi + item.document"
												download
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: $t('tooltip.look'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.304 7.295v20.947c0 .967.79 1.758 1.758 1.758h17.984c.966 0 1.757-.791 1.757-1.758V1.758C25.803.79 25.013 0 24.046 0H11.599" fill="#E74C3C"/><path d="M4.304 7.295H9.84c.967 0 1.758-.791 1.758-1.758V0" fill="#C0392B"/><path d="M2.397 19.653h1.907V17.14l-2.079 2.589c.036-.046.095-.075.172-.075zM27.71 19.653h-1.907V17.14l2.079 2.589c-.036-.046-.095-.075-.172-.075z" fill="#262626"/><path d="M25.907 25.238a.456.456 0 01-.396.275H4.596a.456.456 0 01-.396-.275l-1.994-5.31c-.056-.151.03-.275.19-.275H27.71c.161 0 .246.124.19.275l-1.993 5.31z" fill="#C0392B"/><path d="M9.822 23.974c0-.147.043-.26.13-.34.087-.08.214-.12.38-.12.158 0 .28.04.364.12.084.08.127.194.127.34a.436.436 0 01-.13.337.51.51 0 01-.361.12.547.547 0 01-.374-.12.425.425 0 01-.136-.337zM13.952 21.984c0 .392-.115.694-.346.907-.23.213-.558.32-.983.32h-.265v1.16h-.946v-3.5h1.211c.443 0 .775.097.997.29.221.193.332.467.332.823zm-1.594.455h.172a.47.47 0 00.339-.12.435.435 0 00.126-.33c0-.236-.131-.354-.393-.354h-.244v.804zM17.482 22.547c0 .584-.16 1.035-.483 1.35-.321.317-.773.475-1.356.475H14.51V20.87h1.212c.562 0 .996.144 1.301.431.306.287.459.702.459 1.245zm-.982.034c0-.321-.063-.559-.19-.714-.127-.155-.32-.232-.578-.232h-.276v1.961h.21c.288 0 .499-.083.633-.25.134-.167.201-.422.201-.765zM19.04 24.372h-.93V20.87h2.068v.759h-1.137v.668h1.049v.76h-1.05v1.314zM21.386 10.835c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.199-.586-.44 0-.242.264-.44.586-.44H20.8c.323 0 .586.198.586.44zM21.362 13.471c0 .242-.264.44-.586.44H9.2c-.323 0-.586-.198-.586-.44 0-.241.263-.44.586-.44h11.576c.322 0 .586.199.586.44zM21.386 16.114c0 .241-.263.44-.586.44H9.224c-.322 0-.586-.198-.586-.44s.264-.44.586-.44H20.8c.323 0 .586.198.586.44z" fill="#fff"/></svg>
											</a>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'
import Multiselect from 'vue-multiselect'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			urlApi: urlApi,

			applications: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id', sortable: false },
				{ text: this.$t('headers.contest_date'), value: 'date_at', sortable: false },
				{ text: this.$t('headers.contest_condition'), value: 'condition_desc', sortable: false },
				{ text: this.$t('headers.info_object'), value: 'object_desc', sortable: false },
				{ text: this.$t('headers.info_contest_winner'), value: 'winner_desc', sortable: false },
				{
					text: this.$t('headers.signers_liabilities'),
					value: 'commitments',
					sortable: false,
				},
				{
					text: this.$t('headers.result_pdf'),
					value: 'document',
					align: 'center',
					sortable: false,
					width: '130px',
				},
			],
		}
	},
	props: {
		user: {
			type: Object,
		},
	},
	methods: {
		apiGetUserDocumentList() {
			api
				.get('contest/list')
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				if (
					d.condition_desc
						? d.condition_desc.toLowerCase().includes(this.filters.search.toLowerCase())
						: true
				) {
					return true
				}
			})
		},
	},
	beforeMount() {
		this.apiGetUserDocumentList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
